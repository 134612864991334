$button-bg: (
    primary-button: color(primary-300),
    primary-button-hover: color(primary-305),
    primary-button-active: color(primary-500),

    primary-alt-button: color(base-000),
    primary-alt-button-hover: color(base-050),
    primary-alt-button-active: color(base-000),

    secondary-button: color(success-300),
    secondary-button-hover: color(success-305),
    secondary-button-active: color(success-500),

    danger-button: color(error-300),
    danger-button-hover: color(error-305),
    danger-button-active: color(error-500),

    warn-button: color(warning-300),
    warn-button-hover: color(warning-305),
    warn-button-active: color(warning-500),

    alert-button: color(alert-300),
    alert-button-hover: color(alert-305),
    alert-button-active: color(warning-500),

    login-button: color(primary-300),
    login-button-hover: color(primary-305),
    login-button-active: color(primary-500),

    login-alt-button: color(base-600),
    login-alt-button-hover: color(base-605),
    login-alt-button-active: color(base-700)
);

// Keeping a map for now (despite all values being similar) in case we need to revert the warn button color to black.
$button-text-color: (
    primary-button: color(base-000),
    primary-alt-button: color(primary-300),
    secondary-button: color(base-000),
    danger-button: color(base-000),
    warn-button: color(base-000),
    alert-button: color(base-000),
    login-button: color(base-000),
    login-alt-button: color(base-000)
);

.close {
    color: color(base-500);
    display: block;
    cursor: pointer;
    float: right;
    height: $close-button-size;
    width: $close-button-size;
    text-align: center;
    transition: color .2s linear;

    // Some close buttons has hardcoded symbol
    font-size: 0;
    line-height: 0;

    // Following units are hardcoded because
    // they are really specific to the icon used.
    margin-top: -5px;
    margin-right: -20px;

    &:hover {
        color: color(base-700);
    }

    &::after {
        // Following units are hardcoded because
        // they are really specific to the icon used.
        font-size: $close-button-size - 6px;
        line-height: $close-button-size - 4px;
        display: block;
        content: '\00d7';
    }
}

$color-map: (
    primary: primary,
    primary-alt: primary-alt,
    secondary: secondary,
    danger: danger,
    warn: warn,
    alert: urgent,
    login: login,
    login-alt: login-alt
);

.btn {
    @include line-height(xs);
    @include ellipsis(false);

    appearance: none;
    background-color: color(base-300);
    border-radius: $base-border-radius;
    border: 0;
    box-shadow: none;
    box-sizing: border-box;
    color: color(base-700);
    cursor: pointer;
    display: inline-block;
    padding: math.div(box-model(s), 2) box-model(s);
    text-align: center;
    transition: $base-text-transition, $base-background-color-transition;
    vertical-align: top;

    & + .btn {
        margin-left: box-model(xs);
    }

    &:hover {
        background-color: color(base-305);
        color: color(base-700);
        text-decoration: none; // This is for `a.btn`
    }

    &.active,
    &:active {
        background-color: color(base-500);
    }

    &.disabled,
    &[disabled] {
        opacity: 0.5;
        color: color(base-500);
        cursor: not-allowed;
        outline: none;
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 2px color(primary-300);
    }

    @each $type in primary, secondary, danger, warn, alert, login, primary-alt, login-alt {
        $key: map-get($color-map, $type);

        &.#{$key}, &.btn-#{$key} {
            background-color: map-get($button-bg, #{$type}-button);
            color: map-get($button-text-color, #{$type}-button);

            &:hover {
                background-color: map-get($button-bg, #{$type}-button-hover);
            }

            &.active,
            &:active {
                background-color: map-get($button-bg, #{$type}-button-active);
            }
        }
    }

    &.btn-success {
        @extend .btn-secondary;
    }

    &.mini, &.btn-mini, &.btn-small {
        @extend .badge;

        vertical-align: middle;

        &.primary {
            @extend .badge, .info;
        }

        &.secondary {
            @extend .badge, .success;
        }

        &.primary:hover,
        &.secondary:hover {
            color: color(base-000);
        }

        + .btn.btn-mini {
            margin-left: 0;
        }

        i {
            @include font-size(base);
            @include line-height(xxs);
        }
    }

    &.btn-medium {
        padding: box-model(xxxs, xxs);

        &, &.btn-icon {
            [class^='ic-'], [class*=' ic-'],
            [class^='icon-'], [class*=' icon-'] {
                display: inline-block;
                margin-top: -3px; // icon vertical align vertical position correction, no need for variable
            }
        }
    }

    &.btn-icon {
        [class^='ic-'], [class*=' ic-'],
        [class^='icon-'], [class*=' icon-'] {
            margin: box-model(0, xs, 0, 0);
        }
    }

    &.btn-ctx-icon {
        [class^='ic-'], [class*=' ic-'],
        [class^='icon-'], [class*=' icon-'] {
            margin: box-model(0, 0, 0, xs);
        }
    }


    &.btn-icon, &.btn-ctx-icon {
        &.single {
            width: icon-size(s);
            padding: math.div(box-model(s), 2);

            [class^='ic-'], [class*=' ic-'],
            [class^='icon-'], [class*=' icon-'] {
                margin: 0;
            }

            &.btn-mini {
                padding: 0;
            }
        }
    }

    &.btn-loader {
        position: relative;

        &.loading {
            .text {
                visibility: hidden;
            }
        }

        .loader .loading {
            left: 50%;
        }

        .spinner {
            filter: brightness(3);
            display: block;
            height: box-model(l);
            width: box-model(l);
            margin-top: - box-model(s);
            margin-left: - box-model(s);
        }
    }

    &.btn-login {
        width: 100%;
        height: box-model(xxl);
    }

    &.btn-login-alt {
        @extend .btn-login;
    }

    // TODO: remove !important when we get rid of the legacy styles
    &.no-action {
        cursor: default !important;
    }

    &.important, &.input-block-level {
        display: block;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: box-model(xs);
        width: 100%;
    }

    [class^='ic-'],
    [class*=' ic-'] {
        margin: box-model(0, xs, 0, 0);
    }

    i {
        @include font-size(document);
        vertical-align: middle;
    }
}

// Use this with a link displayed as a button
a.btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &:has(+ button.btn) {
        display: inline-block;
    }

    &.btn-icon.single {
        display: inline-block;
    }
}

button.btn-list-action {
    @include line-height(base);

    appearance: none;
    background: transparent;
    color: color(base-700);
    border: 0;
    cursor: pointer;
    display: block;
    width: 100%;
    padding: 0;
    text-align: left;
}

button.btn {
    + a.btn {
        display: inline-block;
    }
}

input.btn {
    width: auto;
    height: auto;
}

.btn-group {
    display: inline-flex;
    vertical-align: top;
    white-space: nowrap;

    .btn {
        margin-left: 1px;
        margin-right: 1px;
        border-radius: 0;

        &:first-of-type {
            border-top-left-radius: $base-border-radius;
            border-bottom-left-radius: $base-border-radius;
        }

        &:last-of-type {
            border-top-right-radius: $base-border-radius;
            border-bottom-right-radius: $base-border-radius;
        }

        &:only-child {
            border-radius: $base-border-radius;
        }

        + .btn {
            margin-left: 0;
        }
    }
}

.btn-toolbar {
    display: flex;
}

// TODO: remove during phase 3
.package-button {
    display: inline-block;

    .btn {
        max-height: $indirect-button-heights;
    }
}

.action-buttons {
    float: left;

    .btn {
        margin: 0 box-model(xxs);
    }

    .dropdown .menu {
        margin-left: box-model(xxs);
    }
}

.button-and-dropdown {
    @extend .btn;

    // We need this part in order to show the dropdown even if it would be outside of the button container box.
    overflow: visible;
    height: auto;
    line-height: 1;
    padding: box-model(xxs);

    .button-content {
        @include line-height(document);

        width: 100%;
        height: 100%;
        color: color(base-700);
        display: inline-block;

        [class^='ic-'] {
            display: block;
            margin: 0;
        }

        .caption {
            @include font-weight(semibold);
            @include font-size(s);
        }
    }

    .dropdown {
        $dropdown-size: box-model(m);

        height: $dropdown-size;
        width: $dropdown-size;
        margin-left: $dropdown-size * -1;
        vertical-align: top;
        border-radius: $dropdown-activator-border-radius;

        &.in {
            .activator {
                background-color: color(base-305);
            }
        }

        .activator {
            height: 100%;
        }
    }

    &:not([disabled]) {
        .dropdown:hover {
            background-color: color(base-305);
        }
    }
}

.back-button {
    @include font-size(document);

    color: color(base-700);
    cursor: pointer;
    display: inline-block;
    width: icon-size(s);
    height: icon-size(s);
    text-align: center;
    margin-right: box-model(m);

    i {
        line-height: icon-size(s);
    }
}

.leaderboard-update-button {
    i {
        color: color(success-300);
    }
}

.emailing-export-logs {
    .message {
        @include line-height(xxm);
        margin-left: box-model(s);
        display: inline-block;
    }

    a.download {
        margin-left: box-model(xs);

        i {
            margin-left: box-model(xs);
        }
    }
}
