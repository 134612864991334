.content-wrapper.meetings:has(.meetings-calendar) {
    padding-bottom: box-model(xl);
    max-width: none;
}

.meetings-list {
    .title-cell {
        max-width: box-model(xs) * 25;

        .title {
            @include ellipsis;
        }
    }

    .organizer-cell {
        max-width: box-model(xs) * 25;

        .organizer {
            @include ellipsis;
        }
    }

    .date-cell {
        .time {
            color: color(base-500);
        }
    }

    .participants-cell {
        .participants {
            @include ellipsis;
            max-width: box-model(xs) * 20;
            display: block;
        }
    }

    .status-cell {
        max-width: box-model(xs) * 20;
    }

    .title-cell, .organizer-cell, .date-cell, .participants-cell {
        @include line-height(xxs);
    }
}

.meetings-calendar {
    height: calc(100vh - #{box-model(xs) * 9});
    display: flex;
    flex-direction: column;
    min-height: 0;

    > header {
        margin-bottom: box-model(m);
        flex: 0 1 auto;

        > .form-group {
            display: inline-block;
            margin-bottom: 0;
            margin-left: box-model(m);
            vertical-align: middle;
        }

        .toggler {
            margin-bottom: 0;
            vertical-align: middle;
        }
    }

    > footer {
        border-top: 1px solid color(base-300);
        padding-top: box-model(xl);
        flex: 0 1 auto;
    }

    .search-bar {
        float: right;
    }

    .calendar {
        flex: 1 1 auto;
        min-height: 0;
        position: relative;
    }

    .resources-filter {
        position: absolute;
        top: box-model(h) + box-model(xxs);
        right: 0;
        z-index: z-index(menu);
        border: 1px solid color(primary-300);
        background-color: color(primary-100);
        border-radius: box-model(m);
        padding: 0;
        margin-top: box-model(m);
        margin-right: box-model(m);
        width: box-model(m) * 13;
        height: box-model(xl);
        overflow: hidden;
        display: flex;
        flex-direction: column;

        transition:
            background-color ease-in-out .2s,
            border-radius ease-in-out .2s,
            margin-top ease-in-out .2s,
            margin-right ease-in-out .2s,
            width ease-in-out .2s,
            padding ease-in-out .2s,
            height ease-in-out .2s;

        &.open {
            background-color: color(base-000);
            border-radius: 0;
            margin-top: 0;
            margin-right: 0;
            padding: box-model(m);
            width: box-model(m) * 20;
            height: calc(100% - #{box-model(xs) * 12 + box-model(xxs)});

            header {
                cursor: default;
                padding: 0;
            }
        }

        header {
            align-items: center;
            display: flex;
            cursor: pointer;
            padding: box-model(xs, s);
            white-space: nowrap;
            min-height: box-model(m);
            flex: 0 0 auto;

            h5 {
                @include ellipsis;
                margin-right: box-model(xs);
                line-height: 1;
                flex: 1 1 auto;

                i {
                    color: color(primary-300);
                    vertical-align: middle;
                    margin-right: box-model(xs);
                    display: inline-block;
                    margin-top: box-model(-xxs);
                }
            }

            a.close {
                float: none;
                margin: box-model(0, -xs, 0, 0);
            }
        }

        section.filters {
            flex: 1 1 100%;
            overflow: auto;
            margin: box-model(0, -m);
            padding: box-model(0, m);
        }

        .locations {
            padding-top: box-model(l);
        }

        footer {
            text-align: right;
            min-height: 0;
            border-top: 1px solid color(base-300);
            margin-top: box-model(l);
            padding: box-model(l, 0, s);
        }
    }

    .legend {
        .v-popover {
            position: static;
            display: inline-block;
            margin-right: box-model(l);
        }

        .item {
            display: inline-block;
            margin-right: box-model(l);

            &:first-of-type {
                margin-left: box-model(l);
            }
        }

        i {
            display: inline-block;
            width: box-model(s);
            height: box-model(s);
            margin-right: box-model(xs);
            margin-top: box-model(-xxs);
            border-radius: $small-border-radius;
            vertical-align: middle;
        }

        .item.separator i {
            width: 0;
            border-left: 1px solid color(base-300);
            margin-right: 0;
        }

        .confirmed i {
            border: 1px solid color(primary-500);
            background-color: color(primary-050);
        }

        .invited i {
            border: 1px solid color(primary-300);
            background-color: color(base-000);
        }

        .declined i {
            border: 1px solid color(base-500);
            background-color: color(base-100);
        }

        .agenda i {
            border: 1px solid color(alt-1-100);
            background-color: color(alt-1-100);
        }

        .unavailable i {
            background-image: image-url('disabled-slot-bg.svg');
            background-repeat: repeat;
            background-color: transparent;
            border: 1px solid color(base-300);
        }
    }
}

.tooltip .tooltip-inner.meeting-legend-popover {
    max-width: none;
}

.meeting-details-popover {
    max-height: 100%;
    display: flex;
    flex-direction: column;

    .wrapper {
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    &.confirmed .popover-inner {
        border-color: color(primary-500);
    }

    &.invited .popover-inner {
        border-color: color(warning-300);
    }

    &.declined .popover-inner {
        border-color: color(base-600);
    }

    p {
        margin-bottom: 0;
    }

    .popover-inner {
        border-top: box-model(xxs) solid color(primary-500);
        border-radius: $large-border-radius !important;
        width: box-model(m) * 21;
        max-width: none;
        overflow: auto;
    }

    .close {
        margin-right: box-model(-xs);
    }

    header {
        padding-bottom: box-model(s);
        border-bottom: 1px solid color(base-100);
    }

    section {
        padding: box-model(s, 0, 0);

        &.location, &.description {
            border-bottom: 1px solid color(base-100);
            padding-bottom: box-model(s);
        }
    }

    footer {
        padding-top: box-model(s);
        text-align: right;

        button.btn-danger {
            float: left;
        }
    }

    .tooltip-arrow {
        display: none !important;
    }
}

.meeting-person {
    padding-bottom: box-model(s);
    border-bottom: 1px solid color(base-100);
    display: flex;

    & + .meeting-person {
        margin-top: box-model(s);
    }

    .details {
        flex: 1 1 auto;
    }

    .role {
        @include font-size(s);
        color: color(base-500);
    }
}

.meetings-settings {
    .locations {
        .accordion {
            margin-bottom: box-model(l);
        }
    }
}

.meeting-request-status-section {
    background-color: color(base-050);
    border: 1px solid color(base-300);
    border-radius: box-model(s);
    padding: box-model(s, m);

    .status-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

    }

    .status-timestamp {
        @include font-size(s);
        @include line-height(document);
        padding: box-model(xs, 0);

        .label {
            @include font-weight(bold);
        }
    }

    hr {
        border: 1px solid color(base-300);
    }

    .description {
        @include font-size(s);
        @include line-height(document);
        @include font-weight(normal);

        display: flex;
        flex-direction: column;
        gap: box-model(xs);

        p {
            margin: 0;
        }
    }
    
    .actions {
        display: flex;
        flex-direction: column;
        gap: box-model(xs);
        margin-top: box-model(m);
    }
}

.meeting-request-timeslot-picker {
    margin-bottom: box-model(l);

    .timeslots {
        display: flex;
        flex-direction: row;
        gap: box-model(m);

        .timeslot {
            display: flex;
            flex-direction: column;
            padding: box-model(xs, s);
            gap: box-model(xs);
            border-radius: box-model(xs);
            align-items: center;
            border: 1px solid color(base-300);
            cursor: pointer;
            transition: $base-text-transition, $base-background-color-transition;

            &:hover {
                background-color: color(base-305);
            }

            &:active {
                background-color: color(base-500);
            }

            &.selected {
                background-color: color(primary-050);
                border: 1px solid color(primary-300);
            }

            .top-section {
                display: flex;
                flex-direction: column;
                align-items: center;

                .date {
                    @include font-size(s);
                    @include font-weight(normal);
                    @include line-height(document);
                }

                .time {
                    @include font-size(base);
                    @include font-weight(semibold);
                    @include line-height(base);
                }

                .participants {
                    @include font-size(s);
                    @include line-height(document);
                    @include font-weight(normal);
                    color: color(alert-300);

                    &.success {
                        color: color(success-500);
                    }

                    .count {
                        @include font-weight(semibold);
                    }
                }
            }
        
            .rooms {
                @include font-size(s);
                @include line-height(document);
                @include font-weight(normal);
                color: color(base-600);
            }
        }
    }

    .empty-state {
        @include font-size(base);
        @include line-height(base);
        @include font-weight(semibold);
        background-color: color(primary-100);
        border: 1px solid color(primary-300);
        border-left-width: box-model(xs);
        padding: box-model(s, m);
        border-radius: box-model(xxs);
    }
}
