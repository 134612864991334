.badge {
    @include font-size(xs);
    @include line-height(xxs);
    @include font-weight(semibold);
    @include ellipsis;

    border-radius: $base-border-radius;
    color: color(base-700);
    background-color: color(base-300);
    display: inline-block;
    letter-spacing: .3px;
    padding: 0 box-model(xs);
    text-transform: uppercase;
    transition: $base-text-transition, $base-background-color-transition;
    vertical-align: middle;

    * + & {
        margin-left: box-model(xs);
    }

    &.info, &.badge-info {
        color: color(primary-300);
        background-color: color(primary-100);

        &.solid {
            color: color(base-000);
            background-color: color(primary-300);
        }

        &.light {
            color: color(primary-500);
            background-color: color(base-000);
            border-color: color(primary-100);
            border-style: solid;
        }
    }

    &.success, &.badge-success {
        color: color(success-500);
        background-color: color(success-100);

        &.solid {
            color: color(base-000);
            background-color: color(success-500);
        }
    }

    &.warn, &.badge-warn {
        color: color(warning-500);
        background-color: color(warning-100);

        &.solid {
            color: color(base-000);
            background-color: color(warning-300);
        }
    }

    &.alert-alt, &.badge-alert-alt {
        color: color(alert-500);
        background-color: color(alert-100);

        &.solid {
            color: color(base-000);
            background-color: color(alert-300);
        }
    }

    &.danger, &.badge-danger {
        color: color(error-300);
        background-color: color(error-100);

        &.solid {
            color: color(base-000);
            background-color: color(error-300);
        }
    }

    &.alt-2, &.badge-alt-2 {
        color: color(alt-2-300);
        background-color: color(alt-2-100);
    }

    &.secondary, &.badge-secondary {
        color: color(base-000);
        background-color: color(base-500);

        &.light {
            color: color(base-700);
            background-color: color(base-300);
        }
    }

    &.has-action:hover {
        color: color(base-000);
        background-color: color(primary-300);

        &.success {
            color: color(base-000);
            background-color: color(success-300);
        }
    }

    &.user-status {
        margin: 0;
    }

    &.badge-icon {
        padding: 0;
    }

    + .badge {
        margin-left: box-model(xs);
    }

    i:first-child {
        margin-right: box-model(xxs);
    }

    i:last-child {
        margin-left: box-model(xxs);
    }
}
